<template>
  <div class="Topic" v-loading="searchLoading && !pageLoading">
    <Block404 v-if="show404" />
    <div v-else class="content_div">
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{path: '/'}">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{data.title}}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="main">
        <template v-if="datas.length>0">
          <div class="list">
            <div
              v-for="(item, index) in datas"
              :key="index"
              :class="'item'+((item.coverUrl && item.coverUrl.length>0)?' has_pic':'')"
              @click="goInfo(item)"
            >
              <div class="left">
                <div class="name">{{item.title}}</div>
                <div class="time">
                  <span v-if="item.editor && item.editor.length>0">{{item.editor}}</span>
                  <span>{{item.releaseTime}}</span>
                </div>
              </div>
              <div
                v-if="item.coverUrl && item.coverUrl.length>0"
                class="pic"
                :style="'background-image: url('+item.coverUrl+');'"
              ></div>
            </div>
          </div>
          <div class="pagination">
            <div class="left">
              共{{totalPage}}页，当前第
              <span>{{pageNum}}</span>页
            </div>
            <el-pagination
              @current-change="changePage"
              background
              layout="prev, pager, next"
              :page-size.sync="pageSize"
              :current-page.sync="pageNum"
              :total="total"
            ></el-pagination>
          </div>
        </template>
        <div v-else class="empty">
          <Empty />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getTopicInfo, getTopicNews } from "@/axios/topic";
import Block404 from "@/components/Block404.vue";
import Empty from "@/components/Empty.vue";

export default {
  name: "Topic",
  components: {
    Block404,
    Empty
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      id: this.$route.params.id,
      show404: false,
      pageLoading: true,
      data: {},
      datas: [],
      pageSize: 12,
      pageNum: 1,
      total: 0,
      totalPage: 0,
      searchLoading: false
    };
  },
  watch: {
    $route(to) {
      if (to.params.id && to.params.id != this.id) {
        this.id = to.params.id;
        this.show404 = false;
        this.loadData();
      }
    }
  },
  methods: {
    loadData() {
      document.documentElement.scrollTop = 0;
      const loading = this.$loading({
        lock: true,
        background: "rgba(0, 0, 0, 0)"
      });
      this.pageLoading = true;
      this.data = {};
      this.datas = [];
      let query = "id=" + this.id;
      getTopicInfo(query)
        .then(res => {
          if (res.status == 1) {
            this.data = res.data;
            this.pageNum = 1;
            this.loadDatas();
          } else {
            this.show404 = true;
          }
        })
        .catch(err => {
          this.show404 = true;
        })
        .then(() => {
          loading.close();
          this.pageLoading = false;
        });
    },
    changePage(num) {
      this.pageNum = num;
      document.documentElement.scrollTop = 0;
      this.loadDatas();
    },
    loadDatas() {
      this.searchLoading = true;
      let query =
        "pageNum=" +
        this.pageNum +
        "&pageSize=" +
        this.pageSize +
        "&subjectId=" +
        this.id;
      getTopicNews(query)
        .then(res => {
          if (res.status == 1) {
            let data = res.data;
            this.datas = data.list;
            this.total = data.total;
            this.totalPage = Math.ceil(this.total / this.pageSize);
            if (this.datas.length == 0 && this.pageNum > 1) {
              this.pageNum = 1;
              this.loadDatas();
            }
          }
        })
        .catch(err => {})
        .then(() => {
          this.searchLoading = false;
        });
    },
    goInfo(item) {
      window.open("/news/" + item.id);
    }
  }
};
</script>
<style lang="scss" scoped>
.Topic {
  .content_div {
    padding-top: 40px;
    padding-bottom: 60px;
    @include body_block;
    @media (max-width: $mob-screen) {
      padding-top: 16px;
      padding-bottom: 16px;
    }
    .breadcrumb {
      margin-bottom: 20px;
      @media (max-width: $mob-screen) {
        margin-bottom: 8px;
      }
    }
    .main {
      background: #fff;
      padding: 40px 140px 80px 140px;
      @media (max-width: 1100px) {
        padding: 40px;
      }
      @media (max-width: $mob-screen) {
        padding: 16px;
      }
      .list {
        .item {
          margin-bottom: 40px;
          cursor: pointer;
          @media (max-width: $mob-screen) {
            margin-bottom: 20px;
          }
          .left {
            .name {
              line-height: 40px;
              font-size: 28px;
              color: #000;
              font-weight: 400;
              @media (max-width: $mob-screen) {
                line-height: 20px;
                font-size: 16px;
              }
            }
            .time {
              margin-top: 20px;
              line-height: 28px;
              font-size: 20px;
              color: #888;
              @media (max-width: $mob-screen) {
                margin-top: 10px;
                line-height: 20px;
                font-size: 14px;
              }
              span {
                padding-left: 40px;
                &:first-child {
                  padding-left: 0;
                }
                @media (max-width: $mob-screen) {
                  padding-left: 20px;
                }
              }
            }
          }
          &.has_pic {
            @include flex_between_start;
            .left {
              width: calc(100% - 304px);
              @media (max-width: 1000px) {
                width: calc(100% - 240px);
              }
              @media (max-width: 700px) {
                width: calc(100% - 200px);
              }
              @media (max-width: $mob-screen) {
                width: calc(100% - 108px);
              }
            }
            .pic {
              width: 264px;
              height: 150px;
              @include pic_bg_cover;
              @media (max-width: 1000px) {
                width: 200px;
              }
              @media (max-width: 700px) {
                width: 160px;
              }
              @media (max-width: $mob-screen) {
                width: 100px;
                height: 60px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
@for $i from 2 to 6 {
  .page_theme_#{$i} {
    .Topic {
      .content_div {
        .main {
          background: nth($bg_color, $i);
          .list {
            .item {
              border: 1px solid nth($text_color, $i);
              .name {
                color: nth($text_color, $i);
              }
              .time {
                color: nth($text_color, $i);
              }
            }
          }
        }
      }
    }
  }
}
</style>