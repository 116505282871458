<template>
  <div class="Accessibility">
    <div :class="'accessibility_tools only_pc'+(miniTool?' mini':'')">
      <div
        class="accessibility_tools_bar"
        :style="($store.state.pageAccessibility?'':'height: 0;')"
      >
        <div class="accessibility_tools_div">
          <div class="title">无障碍模式</div>
          <div class="scale">
            <div class="label">页面</div>
            <div class="icon" @click="addPageScale">{{miniTool?'放大':''}}</div>
            <div class="icon" @click="subtractPageScale">{{miniTool?'缩小':''}}</div>
          </div>
          <div class="theme">
            <div class="label">修改配色</div>
            <div
              :class="'icon'+($store.state.pageTheme==1?' current':'')"
              @click="setPageTheme(1)"
            >默认</div>
            <div
              :class="'icon'+($store.state.pageTheme==2?' current':'')"
              @click="setPageTheme(2)"
            >字</div>
            <div
              :class="'icon'+($store.state.pageTheme==3?' current':'')"
              @click="setPageTheme(3)"
            >字</div>
            <div
              :class="'icon'+($store.state.pageTheme==4?' current':'')"
              @click="setPageTheme(4)"
            >字</div>
            <div
              :class="'icon'+($store.state.pageTheme==5?' current':'')"
              @click="setPageTheme(5)"
            >字</div>
          </div>
          <div class="line">
            <div class="label">辅助线</div>
            <div :class="'handle'+(pageLine?' show':'')" @click="pageLine=!pageLine">
              <span>{{pageLine?'开':'关'}}</span>
            </div>
          </div>
          <div class="reset" @click="resetPage">重置</div>
          <div class="fold" @click="setMiniTool">{{miniTool?'展开':'折叠'}}</div>
        </div>
      </div>
      <div class="accessibility_tools_bg" :style="($store.state.pageAccessibility?'':'height: 0;')"></div>
    </div>
    <div v-if="pageLine" class="accessibility_line">
      <div class="line_x" :style="'top: '+lineY+'px;'"></div>
      <div class="line_y" :style="'left: '+lineX+'px;'"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Accessibility",
  mounted() {
    document.onmousemove = ev => {
      this.lineX = ev.clientX;
      this.lineY = ev.clientY;
    };
  },
  data() {
    return {
      pageLine: false,
      lineX: 0,
      lineY: 0,
      miniTool: false
    };
  },
  methods: {
    addPageScale() {
      let pageScale = +Math.round(this.$store.state.pageScale);
      if (pageScale < 1) pageScale = 1;
      if (pageScale > 6) pageScale = 6;
      if (pageScale < 6) {
        pageScale += 1;
      }
      if (pageScale > 6) pageScale = 6;
      pageScale = Math.round(pageScale);
      this.$store.commit("setPageScale", pageScale);
    },
    subtractPageScale() {
      let pageScale = +Math.round(this.$store.state.pageScale);
      if (pageScale < 1) pageScale = 1;
      if (pageScale > 6) pageScale = 6;
      if (pageScale > 1) {
        pageScale -= 1;
      }
      if (pageScale < 1) pageScale = 1;
      pageScale = Math.round(pageScale);
      this.$store.commit("setPageScale", pageScale);
    },
    setPageTheme(id) {
      this.$store.commit("setPageTheme", id);
    },
    setMiniTool() {
      this.miniTool = !this.miniTool;
    },
    resetPage() {
      this.$store.commit("setPageScale", 1);
      this.$store.commit("setPageTheme", 1);
      this.pageLine = false;
      this.miniTool = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.Accessibility {
  .accessibility_tools {
    .accessibility_tools_bar {
      overflow: hidden;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1000000;
      transition: all 0.5s;
      height: 80px;
      background: #444550;
      .accessibility_tools_div {
        height: 100%;
        @include body_block;
        @include flex_between_center;
        .title {
          line-height: 37px;
          font-size: 26px;
          font-weight: 500;
          color: #fff;
          @media (max-width: 1100px) {
            display: none;
          }
        }
        .scale {
          @include flex_start_center;
          .label {
            line-height: 28px;
            font-size: 20px;
            font-weight: 500;
            color: #fff;
            @media (max-width: 960px) {
              display: none;
            }
          }
          .icon {
            margin-left: 20px;
            width: 40px;
            height: 40px;
            cursor: pointer;
            @include pic_bg_cover;
            @media (max-width: 720px) {
              margin-left: 10px;
              width: 30px;
              height: 30px;
            }
            &:nth-child(2) {
              background-image: url(../assets/images/accessibility_icon_1.png);
              @media (max-width: 960px) {
                margin-left: 0;
              }
            }
            &:nth-child(3) {
              background-image: url(../assets/images/accessibility_icon_2.png);
            }
          }
        }
        .theme {
          @include flex_start_center;
          .label {
            line-height: 28px;
            font-size: 20px;
            font-weight: 500;
            color: #fff;
            margin-right: 10px;
            @media (max-width: 960px) {
              display: none;
            }
          }
          .icon {
            margin-left: 10px;
            width: 40px;
            height: 40px;
            cursor: pointer;
            line-height: 28px;
            font-size: 20px;
            font-weight: 500;
            @include flex_center_center;
            &.current {
              border: 1px solid #ff3535;
            }
            &:nth-child(2) {
              line-height: 22px;
              font-size: 16px;
              color: $primary_blue;
              background: #ecf0f4;
              @media (max-width: 960px) {
                margin-left: 0;
              }
            }
            &:nth-child(3) {
              color: #ff0;
              background: #00f;
            }
            &:nth-child(4) {
              color: #00f;
              background: #ffc;
            }
            &:nth-child(5) {
              color: #ff0;
              background: #000;
            }
            &:nth-child(6) {
              color: #000;
              background: #fff;
            }
          }
        }
        .line {
          @include flex_start_center;
          .label {
            line-height: 28px;
            font-size: 20px;
            font-weight: 500;
            color: #fff;
            @media (max-width: 960px) {
              display: none;
            }
          }
          .handle {
            margin-left: 20px;
            width: 80px;
            height: 36px;
            border-radius: 18px;
            background: #e5e5e5;
            position: relative;
            cursor: pointer;
            transition: all 0.5s;
            @media (max-width: 960px) {
              margin-left: 0;
            }
            span {
              display: block;
              line-height: 28px;
              font-size: 20px;
              font-weight: 500;
              color: #888;
              position: absolute;
              z-index: 1;
              top: 4px;
              left: 47px;
              transition: all 0.5s;
            }
            &:before {
              display: block;
              content: "";
              width: 40px;
              height: 40px;
              border-radius: 50%;
              background: #fff;
              box-shadow: 0px 2px 4px 0px #0a245e;
              position: absolute;
              z-index: 2;
              top: -2px;
              left: 0;
              transition: all 0.5s;
            }
            &.show {
              background: #ff3535;
              span {
                color: #fff;
                left: 13px;
              }
              &:before {
                left: 40px;
              }
            }
          }
        }
        .reset,
        .fold {
          cursor: pointer;
          width: 94px;
          height: 40px;
          border-radius: 20px;
          line-height: 40px;
          padding-left: 16px;
          background-color: #fff;
          background-repeat: no-repeat;
          background-position: 60px center;
          background-size: 18px auto;
          font-size: 20px;
          font-weight: 500;
          color: $primary_blue;
          @media (max-width: 720px) {
            width: 70px;
            padding-left: 6px;
            background-position: 47px center;
          }
        }
        .reset {
          background-image: url(../assets/images/accessibility_icon_3.png);
        }
        .fold {
          background-image: url(../assets/images/accessibility_icon_4.png);
        }
      }
    }
    .accessibility_tools_bg {
      transition: all 0.5s;
      height: 80px;
    }
    &.mini {
      .accessibility_tools_bar {
        height: 48px;
        .accessibility_tools_div {
          .title {
            line-height: 28px;
            font-size: 20px;
          }
          .scale {
            .label {
              margin-right: 10px;
            }
            .icon {
              margin-left: 10px;
              width: 56px;
              height: 28px;
              background: #fff;
              border-radius: 14px;
              color: $primary_blue;
              line-height: 17px;
              font-size: 12px;
              font-weight: 500;
              @include flex_center_center;
              @media (max-width: 720px) {
                width: 46px;
              }
              &:nth-child(2) {
                @media (max-width: 960px) {
                  margin-left: 0;
                }
              }
            }
          }
          .theme {
            .icon {
              height: 28px;
            }
          }
          .line {
            .handle {
              width: 56px;
              height: 24px;
              border-radius: 12px;
              span {
                line-height: 22px;
                font-size: 16px;
                left: 33px;
                top: 1px;
              }
              &:before {
                width: 28px;
                height: 28px;
              }
              &.show {
                span {
                  left: 7px;
                }
                &:before {
                  left: 28px;
                }
              }
            }
          }
          .reset,
          .fold {
            width: 72px;
            height: 28px;
            border-radius: 14px;
            line-height: 28px;
            padding-left: 0;
            background: #fff;
            text-align: center;
            font-size: 16px;
            @media (max-width: 720px) {
              width: 60px;
            }
          }
        }
      }
      .accessibility_tools_bg {
        height: 48px;
      }
    }
  }
  .accessibility_line {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100000;
    pointer-events: none;
    div {
      border: 2px solid #f00;
      position: absolute;
      &.line_x {
        left: 0;
        right: 0;
      }
      &.line_y {
        top: 0;
        bottom: 0;
      }
    }
  }
}
</style>
