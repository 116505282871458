<template>
  <div class="Search" v-loading="searchLoading">
    <div class="content_div">
      <div class="form">
        <div class="left">
          <div class="icon" @click="doSearch"></div>
          <input placeholder="请输入你想查找的内容" v-model="keywordText" @keyup.enter="doSearch" />
        </div>
        <div class="btn" @click="doSearch">搜索</div>
      </div>
      <template v-if="keyword.length>0">
        <div class="result">“{{keyword}}”{{tab.name}}相关结果约{{total}}条</div>
        <div class="tabs">
          <div
            v-for="(item, index) in tabs"
            :key="index"
            :class="'tab'+((tab.id && item.id==tab.id)?' current':'')"
            @click="clickTab(item)"
          >{{item.name}}</div>
        </div>
        <template v-if="datas.length>0">
          <div class="list">
            <div
              v-for="(item, index) in datas"
              :key="index"
              :class="'item'+((item.coverUrl && item.coverUrl.length>0)?' has_pic':'')"
              @click="goInfo(item)"
            >
              <div class="main">
                <div class="name" v-html="item.title"></div>
                <div class="desc" v-html="item.description"></div>
                <div class="info">
                  <div class="left">
                    <template v-if="item.tags && item.tags.length>0">
                      <div v-for="(tag, tagIndex) in item.tags" :key="tagIndex" class="tag">{{tag}}</div>
                    </template>
                    <div class="source">
                      <span>{{item.editor}}</span>
                      发布在
                      <span>{{item.contentSource}}</span>
                    </div>
                  </div>
                  <div class="time">发布时间：{{item.releaseTime}}</div>
                </div>
              </div>
              <div
                v-if="item.coverUrl && item.coverUrl.length>0"
                class="pic"
                :style="'background-image: url('+item.coverUrl+');'"
              ></div>
            </div>
          </div>
          <div class="pagination">
            <div class="left">
              共{{totalPage}}页，当前第
              <span>{{pageNum}}</span>页
            </div>
            <el-pagination
              @current-change="changePage"
              background
              layout="prev, pager, next"
              :page-size.sync="pageSize"
              :current-page.sync="pageNum"
              :total="total"
            ></el-pagination>
          </div>
        </template>
        <div v-else class="empty">
          <Empty />
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { getSearchColumn, getSearchNews } from "@/axios/search";
import Empty from "@/components/Empty.vue";

export default {
  name: "Search",
  components: {
    Empty
  },
  mounted() {
    this.keyword = this.$route.params.keyword || "";
    this.keywordText = this.$route.params.keyword || "";
    this.tab = this.tabs[0];
    if (this.keyword.length > 0) {
      this.loadColumn();
      this.loadData();
    }
  },
  data() {
    return {
      keyword: "",
      keywordText: "",
      tabs: [
        {
          id: 1,
          name: "全部"
        }
      ],
      tab: {},
      datas: [],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      totalPage: 0,
      searchLoading: false
    };
  },
  watch: {
    $route(to) {
      if (!to.params.keyword || to.params.keyword != this.keyword) {
        this.keyword = to.params.keyword || "";
        this.keywordText = to.params.keyword || "";
        this.tab = this.tabs[0];
        if (this.keyword.length > 0) {
          this.loadColumn();
          this.loadData();
        }
      }
    }
  },
  methods: {
    loadData() {
      document.documentElement.scrollTop = 0;
      if (this.keyword.length > 0) {
        this.searchLoading = true;
        let query =
          "pageNum=" +
          this.pageNum +
          "&pageSize=" +
          this.pageSize +
          "&keyword=" +
          this.keyword;
        if (this.tab.label && this.tab.label.length > 0)
          query += "&columnLabel=" + this.tab.label;
        getSearchNews(query)
          .then(res => {
            if (res.status == 1) {
              let data = res.data,
                keywordReg = new RegExp(this.keyword, "g");
              data.list.forEach(item => {
                item.title = item.title.replace(
                  keywordReg,
                  "<span>" + this.keyword + "</span>"
                );
                item.description = item.description.replace(
                  keywordReg,
                  "<span>" + this.keyword + "</span>"
                );
                if (item.keywords.length > 0)
                  item.tags = item.keywords.split(",");
              });
              this.datas = data.list;
              this.total = data.total;
              this.totalPage = Math.ceil(this.total / this.pageSize);
              if (this.datas.length == 0 && this.pageNum > 1) {
                this.pageNum = 1;
                this.loadData();
              }
            }
          })
          .catch(err => {})
          .then(() => {
            this.searchLoading = false;
          });
      }
    },
    loadColumn() {
      if (this.keyword.length > 0) {
        let query = "keyword=" + this.keyword;
        getSearchColumn(query).then(res => {
          let tabs = [
            {
              id: 1,
              name: "全部"
            }
          ];
          if (res.status == 1) {
            tabs = [...tabs, ...res.data];
          }
          this.tabs = tabs;
        });
      }
    },
    doSearch() {
      this.keyword = this.keywordText;
      this.tab = this.tabs[0];
      this.loadColumn();
      this.changePage(1);
    },
    clickTab(item) {
      this.tab = item;
      this.changePage(1);
    },
    changePage(num) {
      this.pageNum = num;
      document.documentElement.scrollTop = 0;
      this.loadData();
    },
    goInfo(item) {
      window.open("/news/" + item.id);
    }
  }
};
</script>
<style lang="scss" scoped>
.Search {
  background: #fff;
  @include content_block;
  .content_div {
    padding-top: 48px;
    padding-bottom: 60px;
    @include body_block;
    @media (max-width: $mob-screen) {
      padding-top: 16px;
      padding-bottom: 16px;
    }
    .form {
      @include flex_between_center;
      .left {
        width: calc(100% - 268px);
        border-radius: 6px;
        padding-left: 20px;
        padding-right: 20px;
        height: 68px;
        background: #ecf0f4 url(../assets/images/search_icon.png) no-repeat 20px
          center;
        background-size: 20px auto;
        @include flex_center_center;
        @media (max-width: $mob-screen) {
          width: calc(100% - 88px);
          border-radius: 4px;
          padding-left: 8px;
          padding-right: 8px;
          height: 30px;
          background-position: 8px center;
          background-size: 12px auto;
        }
        .icon {
          flex-shrink: 0;
          width: 20px;
          height: 100%;
          margin-right: 20px;
          cursor: pointer;
          @media (max-width: $mob-screen) {
            width: 12px;
            margin-right: 8px;
          }
        }
        input {
          display: block;
          flex-grow: 1;
          line-height: 28px;
          font-size: 20px;
          color: #000;
          background: transparent;
          @media (max-width: $mob-screen) {
            line-height: 18px;
            font-size: 14px;
          }
        }
      }
      .btn {
        width: 248px;
        height: 68px;
        line-height: 68px;
        background: $primary_red;
        text-align: center;
        border-radius: 6px;
        font-size: 20px;
        color: #fff;
        font-weight: 500;
        cursor: pointer;
        @media (max-width: $mob-screen) {
          width: 80px;
          height: 30px;
          line-height: 30px;
          border-radius: 4px;
          font-size: 14px;
        }
      }
    }
    .result {
      margin-top: 40px;
      line-height: 28px;
      font-size: 20px;
      color: #888;
      @media (max-width: $mob-screen) {
        margin-top: 16px;
        line-height: 18px;
        font-size: 14px;
      }
    }
    .tabs {
      margin-top: 20px;
      padding-bottom: 22px;
      border-bottom: 1px solid #e5e5e5;
      @include flex_start_start;
      flex-wrap: wrap;
      @media (max-width: $mob-screen) {
        margin-top: 16px;
        padding-bottom: 8px;
      }
      .tab {
        width: calc(12.5% - 28px);
        height: 48px;
        line-height: 48px;
        text-align: center;
        background: #e7eaf0;
        font-size: 20px;
        color: $primary_blue;
        cursor: pointer;
        margin-left: 32px;
        margin-bottom: 18px;
        @media (max-width: 1000px) {
          width: calc(12.5% - 14px);
          margin-left: 16px;
        }
        @media (max-width: 700px) {
          width: calc(12.5% - 7px);
          margin-left: 8px;
        }
        &:nth-child(8n + 1) {
          margin-left: 0;
        }
        &.current {
          background: $primary_blue;
          color: #fff;
        }
        @media (max-width: $mob-screen) {
          width: calc(25% - 12px);
          height: 24px;
          line-height: 24px;
          font-size: 16px;
          margin-right: 0;
          margin-left: 16px;
          margin-bottom: 8px;
        }
        &:nth-child(4n + 1) {
          @media (max-width: $mob-screen) {
            margin-left: 0;
          }
        }
      }
    }
    .list {
      margin-top: 40px;
      @media (max-width: $mob-screen) {
        margin-top: 16px;
      }
      .item {
        margin-bottom: 67px;
        cursor: pointer;
        @media (max-width: $mob-screen) {
          margin-bottom: 24px;
        }
        .main {
          .name {
            line-height: 40px;
            font-size: 28px;
            color: #000;
            font-weight: 600;
            /deep/ span {
              color: $primary_red;
            }
            @media (max-width: $mob-screen) {
              line-height: 20px;
              font-size: 16px;
            }
          }
          .desc {
            margin-top: 20px;
            line-height: 36px;
            font-size: 20px;
            color: #444;
            /deep/ span {
              color: $primary_red;
            }
            @media (max-width: $mob-screen) {
              margin-top: 8px;
              line-height: 20px;
              font-size: 14px;
            }
          }
          .info {
            margin-top: 19px;
            @include flex_between_center;
            @media (max-width: $mob-screen) {
              margin-top: 8px;
              display: block;
            }
            .left {
              @include flex_start_center;
              flex-wrap: wrap;
              .tag {
                padding: 0 10px;
                line-height: 34px;
                background: rgba(22, 49, 112, 0.09);
                border-radius: 6px;
                font-size: 20px;
                color: $primary_blue;
                margin-top: 1px;
                margin-bottom: 1px;
                margin-right: 20px;
                @media (max-width: $mob-screen) {
                  padding: 0 8px;
                  line-height: 20px;
                  border-radius: 4px;
                  font-size: 14px;
                  margin-right: 8px;
                }
              }
              .source {
                line-height: 28px;
                font-size: 20px;
                color: #888;
                span {
                  text-decoration: underline;
                }
                @media (max-width: $mob-screen) {
                  line-height: 20px;
                  font-size: 14px;
                }
              }
            }
            .time {
              padding-left: 20px;
              line-height: 22px;
              font-size: 16px;
              color: #888;
              flex-shrink: 0;
              @media (max-width: $mob-screen) {
                padding-left: 0;
                line-height: 20px;
                font-size: 12px;
              }
            }
          }
        }
        &.has_pic {
          @include flex_between_start;
          .main {
            width: calc(100% - 483px);
            @media (max-width: 1000px) {
              width: calc(100% - 280px);
            }
            @media (max-width: 700px) {
              width: calc(100% - 220px);
            }
            @media (max-width: $mob-screen) {
              width: calc(100% - 108px);
            }
          }
          .pic {
            width: 443px;
            height: 252px;
            @include pic_bg_cover;
            @media (max-width: 1000px) {
              width: 240px;
              height: 160px;
            }
            @media (max-width: 700px) {
              width: 180px;
              height: 100px;
            }
            @media (max-width: $mob-screen) {
              width: 100px;
              height: 60px;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
@for $i from 2 to 6 {
  .page_theme_#{$i} {
    .Search {
      background: nth($bg_color, $i);
      .content_div {
        .form {
          .left {
            border: 1px solid nth($text_color, $i);
            background-color: nth($bg_color, $i);
            input {
              color: nth($text_color, $i);
            }
          }
          .btn {
            background: nth($bg_color, $i);
            color: nth($text_color, $i);
            border: 1px solid nth($text_color, $i);
          }
        }
        .result {
          color: nth($text_color, $i);
        }
        .tabs {
          border-bottom: 1px solid nth($text_color, $i);
          .tab {
            background: nth($bg_color, $i);
            color: nth($text_color, $i);
            &.current {
              border: 1px solid nth($text_color, $i);
              background: nth($bg_color, $i);
              color: nth($text_color, $i);
            }
          }
        }
        .list {
          .item {
            .main {
              .name {
                color: nth($text_color, $i);
                span {
                  color: nth($text_color, $i);
                }
              }
              .desc {
                color: nth($text_color, $i);
                span {
                  color: nth($text_color, $i);
                }
              }
              .info {
                .left {
                  .tag {
                    background: nth($bg_color, $i);
                    color: nth($text_color, $i);
                  }
                  .source {
                    color: nth($text_color, $i);
                  }
                }
                .time {
                  color: nth($text_color, $i);
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>