<template>
  <div class="Empty">
    <i class="el-icon-folder-opened"></i>
    <span>{{(text && text.length>0)?text:'暂无数据'}}</span>
  </div>
</template>
<script>
export default {
  name: "Empty",
  props: ["text"]
};
</script>
<style lang="scss" scoped>
.Empty {
  padding: 80px 0;
  @include flex_center_center;
  flex-direction: column;
  i {
    font-size: 160px;
    color: #e5e5e5;
  }
  span {
    margin: 20px;
    line-height: 22px;
    font-size: 14px;
    color: #888;
  }
}
</style>
<style lang="scss">
@for $i from 2 to 6 {
  .page_theme_#{$i} {
    .Empty {
      i,
      span {
        color: nth($text_color, $i);
      }
    }
  }
}
</style>