import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    pageAccessibility: false,
    pageScale: 1,
    pageTheme: 1
  },
  mutations: {
    setPageAccessibility(state, payload) {
      state.pageAccessibility = payload
    },
    setPageScale(state, payload) {
      state.pageScale = payload
    },
    setPageTheme(state, payload) {
      state.pageTheme = payload
    }
  }
})

export default store