<template>
  <div class="Petition">
    <div class="content_div">
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{path: '/'}">首页</el-breadcrumb-item>
          <el-breadcrumb-item>网上信访</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="top">
        <div class="title">网上信访</div>
        <div class="desc">
          1、不得发布造谣，诽谤他人的言论以及未证实的消息。
          <br />2、请勿发表任何形式的广告、企业推广产品或服务消息。
          <br />3、请勿填写与消防部门职能无关的信息。
          <br />4、填写留下真实有效的信息，严格保护您的个人隐私。
          <br />5、在提交信息时，须遵守国家相关法律法规，并承担一切因留言行为而直接或间接引起的法律责任。
        </div>
      </div>
      <div class="form" v-loading="searchLoading">
        <div class="msg">提示：请您如实填写，带※号的为必填项，我们将对您的个人信息保密。</div>
        <div class="line">
          <div class="label">
            <span>*</span>姓名:
          </div>
          <div class="value">
            <input v-model="name" />
          </div>
        </div>
        <div class="line">
          <div class="label">
            <span>*</span>手机号码:
          </div>
          <div class="value">
            <input v-model="tel" />
          </div>
        </div>
        <div class="line">
          <div class="label">
            <span>*</span>所属类型:
          </div>
          <div class="value select">
            <div class="select_top">
              <span :class="currentType.id==0?'empty':''">{{currentType.name}}</span>
            </div>
            <select @change="changeType" v-model="type">
              <option v-for="(item, index) in types" :key="index" :value="item.id">{{item.name}}</option>
            </select>
          </div>
        </div>
        <div class="line">
          <div class="label">
            <span>*</span>标题:
          </div>
          <div class="value textarea title">
            <textarea
              v-model="title"
              :maxlength="maxTitle"
              :placeholder="'请将标题限制在'+maxTitle+'字以内'"
            />
            <span>{{title.length}}/{{maxTitle}}</span>
          </div>
        </div>
        <div class="line">
          <div class="label">
            <span>*</span>内容:
          </div>
          <div class="value textarea desc">
            <textarea v-model="desc" :maxlength="maxDesc" :placeholder="'请将内容限制在'+maxDesc+'字以内'" />
            <span>{{desc.length}}/{{maxDesc}}</span>
          </div>
        </div>
        <div class="btn">
          <div class="submit_btn" @click="doSubmit">发送</div>
          <div class="reset_btn" @click="doReset">重填</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { addMessage } from "@/axios/petition";

export default {
  name: "Petition",
  mounted() {
    this.currentType = this.types[0];
    this.type = this.currentType.id;
    if (this.$route.params.type) {
      this.types.forEach(item => {
        if (item.id == this.$route.params.type) {
          this.type = item.id;
          this.currentType = item;
        }
      });
    }
    const loading = this.$loading({
      lock: true,
      background: "rgba(0, 0, 0, 0)"
    });
    setTimeout(() => {
      loading.close();
    }, 100);
  },
  data() {
    return {
      name: "",
      tel: "",
      types: [
        {
          id: 0,
          name: "请选择所属类型"
        },
        {
          id: 1,
          name: "公众留言"
        },
        {
          id: 2,
          name: "投诉举报"
        }
      ],
      type: 0,
      currentType: {},
      title: "",
      desc: "",
      maxTitle: 50,
      maxDesc: 200,
      searchLoading: false
    };
  },
  watch: {
    $route(to) {
      if (!to.params.type || to.params.type != this.type) {
        this.currentType = this.types[0];
        this.type = this.currentType.id;
        if (to.params.type) {
          this.types.forEach(item => {
            if (item.id == to.params.type) {
              this.type = item.id;
              this.currentType = item;
            }
          });
        }
      }
    }
  },
  methods: {
    changeType() {
      this.types.forEach(item => {
        if (item.id == this.type) {
          this.currentType = item;
        }
      });
    },
    doSubmit() {
      if (this.name.length == 0) {
        this.$message({
          message: "请输入姓名",
          type: "error"
        });
      } else if (this.tel.length == 0) {
        this.$message({
          message: "请输入手机号码",
          type: "error"
        });
      } else if (this.type == 0) {
        this.$message({
          message: "请选择所属类型",
          type: "error"
        });
      } else if (this.title.length == 0) {
        this.$message({
          message: "请输入标题",
          type: "error"
        });
      } else if (this.desc.length == 0) {
        this.$message({
          message: "请输入内容",
          type: "error"
        });
      } else {
        this.searchLoading = true;
        let params = {
          type: this.type + "",
          title: this.title,
          content: this.desc,
          name: this.name,
          phone: this.tel
        };
        addMessage(params)
          .then(res => {
            if (res.status == 1) {
              this.doReset();
              this.$message({
                message: "信息已发送",
                type: "success"
              });
            } else {
              let msg = "信息发送失败";
              if (res.msg && res.msg.length > 0) msg = res.msg;
              this.$message({
                message: msg,
                type: "error"
              });
            }
          })
          .catch(err => {
            let msg = "信息发送失败";
            if (err.data && err.data.msg && err.data.msg.length > 0)
              msg = err.data.msg;
            this.$message({
              message: msg,
              type: "error"
            });
          })
          .then(() => {
            this.searchLoading = false;
          });
      }
    },
    doReset() {
      this.name = "";
      this.tel = "";
      this.currentType = this.types[0];
      this.type = this.currentType.id;
      if (this.$route.params.type) {
        this.types.forEach(item => {
          if (item.id == this.$route.params.type) {
            this.type = item.id;
            this.currentType = item;
          }
        });
      }
      this.title = "";
      this.desc = "";
    }
  }
};
</script>
<style lang="scss" scoped>
.Petition {
  .content_div {
    padding-top: 40px;
    padding-bottom: 60px;
    @include body_block;
    @media (max-width: $mob-screen) {
      padding-top: 16px;
      padding-bottom: 16px;
    }
    .breadcrumb {
      margin-bottom: 20px;
      @media (max-width: $mob-screen) {
        margin-bottom: 8px;
      }
    }
    .top {
      background: #0097c4 url(../assets/images/petition_top.png) no-repeat
        center center;
      background-size: cover;
      padding: 56px 0;
      @media (max-width: 960px) {
        padding: 32px;
      }
      @media (max-width: $mob-screen) {
        padding: 16px;
      }
      .title {
        width: 767px;
        margin: 0 auto;
        line-height: 57px;
        font-size: 40px;
        font-weight: 800;
        color: #fff;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
        @media (max-width: 960px) {
          width: 100%;
        }
        @media (max-width: $mob-screen) {
          line-height: 30px;
          font-size: 20px;
        }
      }
      .desc {
        width: 767px;
        margin: 0 auto;
        margin-top: 25px;
        line-height: 34px;
        font-size: 20px;
        font-weight: 500;
        color: #fff;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
        @media (max-width: 960px) {
          width: 100%;
        }
        @media (max-width: $mob-screen) {
          margin-top: 8px;
          line-height: 20px;
          font-size: 14px;
        }
      }
    }
    .form {
      background: #fff;
      padding: 80px 0;
      @media (max-width: 960px) {
        padding: 32px;
      }
      @media (max-width: $mob-screen) {
        padding: 16px;
      }
      .msg {
        width: 767px;
        margin: 0 auto;
        padding: 10px;
        line-height: 28px;
        font-size: 20px;
        font-weight: 500;
        color: #fff;
        background: $primary_red;
        text-align: center;
        @media (max-width: 960px) {
          width: 100%;
        }
        @media (max-width: $mob-screen) {
          padding: 8px;
          line-height: 22px;
          font-size: 14px;
        }
      }
      .line {
        width: 767px;
        margin: 0 auto;
        margin-top: 40px;
        @include flex_between_start;
        @media (max-width: 960px) {
          width: 100%;
        }
        @media (max-width: $mob-screen) {
          margin-top: 16px;
        }
        .label {
          height: 48px;
          line-height: 28px;
          font-size: 20px;
          font-weight: 500;
          color: #000;
          @include flex_start_center;
          @media (max-width: $mob-screen) {
            height: 30px;
            line-height: 22px;
            font-size: 14px;
          }
          span {
            color: $primary_red;
            padding-right: 8px;
            @media (max-width: $mob-screen) {
              padding-right: 4px;
            }
          }
        }
        .value {
          width: calc(100% - 141px);
          background: #ecf0f4;
          height: 48px;
          padding: 0 20px;
          @include flex_start_center;
          @media (max-width: $mob-screen) {
            width: calc(100% - 90px);
            height: 30px;
            padding: 0 8px;
          }
          input {
            display: block;
            width: 100%;
            line-height: 22px;
            font-size: 16px;
            color: #000;
            background: transparent;
            @media (max-width: $mob-screen) {
              line-height: 18px;
              font-size: 12px;
            }
          }
          &.select {
            position: relative;
            overflow: hidden;
            padding: 0;
            select {
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              width: 100%;
              z-index: 1;
            }
            .select_top {
              position: relative;
              z-index: 2;
              pointer-events: none;
              width: 100%;
              height: 100%;
              @include flex_start_center;
              padding-left: 20px;
              padding-right: 68px;
              line-height: 22px;
              background: #ecf0f4;
              @media (max-width: $mob-screen) {
                padding-left: 8px;
                padding-right: 38px;
                line-height: 18px;
                font-size: 12px;
              }
              span {
                display: block;
                font-size: 16px;
                color: #000;
                @media (max-width: $mob-screen) {
                  font-size: 12px;
                }
                &.empty {
                  color: #888;
                }
              }
              &:after {
                display: block;
                content: "";
                width: 48px;
                background: $primary_blue
                  url(../assets/images/header_menu_down_arrow.png) no-repeat
                  center center;
                background-size: 8px auto;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                @media (max-width: $mob-screen) {
                  width: 30px;
                  background-size: 6px auto;
                }
              }
            }
          }
          &.textarea {
            padding-top: 13px;
            padding-bottom: 13px;
            height: auto;
            @include flex_between_end;
            @media (max-width: $mob-screen) {
              display: block;
              padding-top: 6px;
              padding-bottom: 6px;
            }
            textarea {
              display: block;
              width: calc(100% - 70px);
              line-height: 22px;
              font-size: 16px;
              color: #000;
              background: transparent;
              @media (max-width: $mob-screen) {
                width: 100%;
                line-height: 18px;
                font-size: 12px;
              }
            }
            span {
              line-height: 22px;
              font-size: 16px;
              color: #888;
              @media (max-width: $mob-screen) {
                line-height: 18px;
                font-size: 12px;
                display: block;
                text-align: right;
              }
            }
          }
          &.title {
            textarea {
              height: 44px;
              @media (max-width: $mob-screen) {
                height: 36px;
              }
            }
          }
          &.desc {
            textarea {
              height: 160px;
              @media (max-width: $mob-screen) {
                height: 80px;
              }
            }
          }
        }
      }
      .btn {
        width: 767px;
        margin: 0 auto;
        margin-top: 40px;
        padding-left: 141px;
        @include flex_start_center;
        @media (max-width: 960px) {
          width: 100%;
        }
        @media (max-width: $mob-screen) {
          padding-left: 90px;
          margin-top: 16px;
        }
        div {
          line-height: 48px;
          text-align: center;
          font-size: 16px;
          color: #fff;
          font-weight: 500;
          cursor: pointer;
          @media (max-width: $mob-screen) {
            line-height: 30px;
            font-size: 14px;
          }
        }
        .submit_btn {
          width: 200px;
          background: $primary_blue;
          margin-right: 20px;
          @media (max-width: $mob-screen) {
            width: 100px;
            margin-right: 8px;
          }
        }
        .reset_btn {
          width: 100px;
          background: $primary_red;
          @media (max-width: $mob-screen) {
            width: 60px;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
@for $i from 2 to 6 {
  .page_theme_#{$i} {
    .Petition {
      .content_div {
        .top {
          background: nth($bg_color, $i);
          .title {
            color: nth($text_color, $i);
          }
          .desc {
            color: nth($text_color, $i);
          }
        }
        .form {
          background: nth($bg_color, $i);
          .msg {
            color: nth($text_color, $i);
            background: nth($bg_color, $i);
          }
          .line {
            .label {
              color: nth($text_color, $i);
              span {
                color: nth($text_color, $i);
              }
            }
            .value {
              background: nth($bg_color, $i);
              border: 1px solid nth($text_color, $i);
              input {
                color: nth($text_color, $i);
              }
              &.select {
                .select_top {
                  background: nth($bg_color, $i);
                  span {
                    color: nth($text_color, $i);
                    &.empty {
                      color: nth($text_color, $i);
                    }
                  }
                  &:after {
                    background-color: nth($text_color, $i);
                  }
                }
              }
              &.textarea {
                textarea {
                  color: nth($text_color, $i);
                }
                span {
                  color: nth($text_color, $i);
                }
              }
            }
          }
          .btn {
            div {
              color: nth($text_color, $i);
              border: 1px solid nth($text_color, $i);
            }
            .submit_btn {
              background: nth($bg_color, $i);
            }
            .reset_btn {
              background: nth($bg_color, $i);
            }
          }
        }
      }
    }
  }
}
</style>