<template>
  <div class="Block404">
    <h1>404</h1>页面没有找到
  </div>
</template>
<script>
export default {
  name: "Block404"
};
</script>
<style lang="scss" scoped>
.Block404 {
  font-size: 50px;
  padding: 80px 0;
  @include body_block;
  @include flex_center_center;
  flex-direction: column;
  h1 {
    color: $primary_blue;
  }
}
</style>
<style lang="scss">
@for $i from 2 to 6 {
  .page_theme_#{$i} {
    .Block404 {
      h1 {
        color: nth($text_color, $i);
      }
    }
  }
}
</style>