import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import './element-variables.scss'
import VueMeta from 'vue-meta'
import utils from './util.js'

Vue.use(ElementUI)
Vue.use(VueMeta)
Vue.use(utils)

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')