<template>
  <div id="app">
    <Accessibility />
    <div :class="'page_scale_'+$store.state.pageScale+' page_theme_'+$store.state.pageTheme">
      <Header />
      <div class="page">
        <keep-alive :include="alivePages">
          <router-view />
        </keep-alive>
      </div>
      <Footer />
    </div>
  </div>
</template>
<script>
import Accessibility from "@/components/Accessibility.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: {
    Accessibility,
    Header,
    Footer
  },
  data() {
    return {
      alivePages: ["Home", "Column", "Topic"]
    };
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "SiteDomain",
          content: location.host
        }
      ]
    };
  }
};
</script>
<style lang="scss">
.only_pc {
  display: block;
  @media (max-width: $mob-screen) {
    display: none;
  }
}
.only_mob {
  display: none;
  @media (max-width: $mob-screen) {
    display: block;
  }
}
@for $i from 2 to 7 {
  .page_scale_#{$i} {
    transform-origin: left top;
    transform: scale((1 + ($i - 1) * 0.2));
  }
}
@for $i from 2 to 6 {
  .page_theme_#{$i} {
    color: nth($text_color, $i);
    .page {
      background: nth($bg_color, $i);
    }
    .pagination {
      .left {
        color: nth($text_color, $i) !important;
        span {
          color: nth($text_color, $i) !important;
        }
      }
      .el-pagination.is-background {
        color: nth($text_color, $i) !important;
        .el-pager {
          li {
            color: nth($text_color, $i) !important;
            background-color: nth($bg_color, $i) !important;
            @media (max-width: $mob-screen) {
              background: nth($bg_color, $i) !important;
            }
            &.active {
              border: 1px solid nth($text_color, $i);
            }
          }
        }
        button {
          background: nth($bg_color, $i) !important;
          @media (max-width: $mob-screen) {
            background: nth($bg_color, $i) !important;
          }
        }
      }
    }
    .breadcrumb {
      .el-breadcrumb {
        .el-breadcrumb__inner {
          color: nth($text_color, $i) !important;
        }
        .el-breadcrumb__separator {
          color: nth($text_color, $i) !important;
        }
        .el-breadcrumb__item {
          &:last-child {
            .el-breadcrumb__inner {
              color: nth($text_color, $i) !important;
            }
          }
        }
      }
    }
  }
}
.page {
  @include content_block;
}
</style>