<template>
  <div class="Page404">
    <Block404 />
  </div>
</template>
<script>
import Block404 from "@/components/Block404.vue";

export default {
  name: "Page404",
  components: {
    Block404
  }
};
</script>