<template>
  <div class="Header">
    <div class="only_pc">
      <div class="top">
        <router-link class="logo" to="/"></router-link>
        <div class="right">
          <div v-if="!isSearch" class="search">
            <div class="icon" @click="doSearch"></div>
            <input placeholder="请输入你想查找的内容" v-model="keyword" @keyup.enter="doSearch" />
          </div>
          <div class="accessibility" @click="clickAccessibility">无障碍</div>
        </div>
      </div>
      <div class="bottom">
        <div class="menu">
          <div v-for="(item, index) in menu" :key="index" class="menu_item">
            <div
              :class="
                    'menu_link' +
                      (item.children && item.children.length > 0
                        ? ' has_child'
                        : '')
                  "
              @click="goMenu(item)"
            >{{ item.name }}</div>
            <div v-if="item.children && item.children.length > 0" class="menu_children">
              <div
                v-for="(childItem, childIndex) in item.children"
                :key="childIndex"
                class="menu_children_item"
              >
                <div class="menu_children_link" @click="goMenu(childItem)">{{ childItem.name }}</div>
                <div
                  v-if="childItem.children && childItem.children.length > 0"
                  class="menu_children_children"
                >
                  <div
                    v-for="(child2Item, child2Index) in childItem.children"
                    :key="child2Index"
                    class="menu_children_link"
                    @click="goMenu(child2Item)"
                  >{{ child2Item.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="only_mob">
      <div class="top">
        <div :class="'menu_icon' + (showMenu ? ' close' : '')" @click="showMenu = !showMenu">
          <span></span>
        </div>
        <router-link class="logo" to="/"></router-link>
        <div v-if="isSearch" class="empty"></div>
        <div v-else class="search" @click="goSearch"></div>
      </div>
      <div v-if="showMenu" class="menu">
        <div class="menu_list">
          <div v-for="(item, index) in menu" :key="index" class="menu_item">
            <div
              :class="
                'menu_link' +
                  (item.children && item.children.length > 0
                    ? ' has_child'
                    : '')
              "
              @click="goMenu(item)"
            >{{ item.name }}</div>
            <div v-if="item.children && item.children.length > 0" class="menu_children">
              <div
                v-for="(childItem, childIndex) in item.children"
                :key="childIndex"
                class="menu_children_item"
              >
                <div class="menu_children_link" @click="goMenu(childItem)">{{ childItem.name }}</div>
                <div
                  v-if="childItem.children && childItem.children.length > 0"
                  class="menu_children_children"
                >
                  <div
                    v-for="(child2Item, child2Index) in childItem.children"
                    :key="child2Index"
                    class="menu_children_link"
                    @click="goMenu(child2Item)"
                  >{{ child2Item.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getHeaderMenu } from "@/axios/header";

export default {
  name: "Header",
  data() {
    return {
      keyword: "",
      menu: [],
      showMenu: false,
      isSearch: this.$route.name == "Search"
    };
  },
  mounted() {
    this.loadMenu();
    this.showMenu = false;
  },
  watch: {
    $route(to) {
      this.isSearch = to.name == "Search";
      this.keyword = "";
    }
  },
  methods: {
    loadMenu() {
      getHeaderMenu().then(res => {
        if (res.status == 1) {
          this.menu = res.data;
        }
      });
    },
    goMenu(item) {
      if (item.path)
        this.$router.push({
          path: item.path
        });
      this.showMenu = false;
    },
    doSearch() {
      let go = "/search";
      if (this.keyword.length > 0) go += "/" + encodeURIComponent(this.keyword);
      this.$router.push({
        path: go
      });
    },
    goSearch() {
      this.$router.push({
        path: "/search"
      });
    },
    clickAccessibility() {
      this.$store.commit(
        "setPageAccessibility",
        !this.$store.state.pageAccessibility
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.Header {
  background: #fff;
  @media print {
    display: none;
  }
  .only_pc {
    .top {
      height: 120px;
      @include body_block;
      @include flex_between_center;
      .logo {
        display: blcok;
        width: 437px;
        height: 87px;
        @include pic_bg_cover;
        background-image: url(../assets/images/logo.png);
        @media (max-width: 820px) {
          width: 220px;
          height: 44px;
        }
      }
      .right {
        line-height: 21px;
        font-size: 16px;
        @include flex_end_center;
        .search {
          width: 178px;
          height: 36px;
          border-radius: 5px;
          padding-left: 11px;
          padding-right: 10px;
          background: #ecf0f4 url(../assets/images/header_search.png) no-repeat
            11px center;
          background-size: 16px auto;
          @include flex_center_center;
          .icon {
            flex-shrink: 0;
            width: 16px;
            height: 100%;
            margin-right: 7px;
            cursor: pointer;
          }
          input {
            display: block;
            flex-grow: 1;
            background: transparent;
            line-height: 17px;
            font-size: 12px;
            color: #161370;
          }
        }
        .accessibility {
          margin-left: 40px;
          height: 22px;
          padding-left: 26px;
          padding-right: 10px;
          line-height: 22px;
          font-size: 14px;
          color: #161370;
          border-radius: 11px;
          background: rgba(22, 49, 112, 0.1)
            url(../assets/images/header_accessibility.png) no-repeat 9px center;
          background-size: 13px auto;
          cursor: pointer;
          @media (max-width: 780px) {
            margin-left: 16px;
          }
        }
      }
    }
    .bottom {
      background: $primary_blue;
      .menu {
        height: 60px;
        @include body_block;
        @include flex_start_center;
        .menu_item {
          flex: 1;
          position: relative;
          .menu_link {
            display: block;
            height: 60px;
            padding-left: 20px;
            line-height: 30px;
            font-size: 22px;
            font-weight: 600;
            color: #fff;
            cursor: pointer;
            @include flex_start_center;
            @media (max-width: 1140px) {
              padding-left: 10px;
            }
            @media (max-width: 920px) {
              padding-left: 4px;
              font-size: 12px;
            }
            @media (max-width: 700px) {
              padding-left: 2px;
            }
            &.has_child {
              &:after {
                display: block;
                content: "";
                width: 8px;
                height: 8px;
                margin-left: 4px;
                @include pic_bg_cover;
                background-image: url(../assets/images/header_menu_down_arrow.png);
              }
            }
          }
          .menu_children {
            display: none;
          }
          &:hover {
            .menu_link {
              background: $primary_red;
              &:after {
                background-image: url(../assets/images/header_menu_up_arrow.png);
              }
            }
            .menu_children {
              display: block;
              width: 100%;
              position: absolute;
              top: 60px;
              left: 0;
              z-index: 100;
              background: #fff;
              box-shadow: 0px 0px 20px 0px rgba(91, 92, 99, 0.1);
              .menu_children_item {
                .menu_children_link {
                  padding: 12px 16px;
                  line-height: 25px;
                  font-size: 18px;
                  font-weight: 500;
                  color: #444;
                  cursor: pointer;
                  border-left: 4px solid #fff;
                  @include flex_start_center;
                  @media (max-width: 920px) {
                    font-size: 12px;
                  }
                  &:hover {
                    background: #f5f5f5;
                    border-left: 4px solid $primary_red;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .only_mob {
    border-bottom: 1px solid $primary_blue;
    .top {
      height: 75px;
      padding: 0 18px;
      @include flex_between_center;
      .menu_icon {
        width: 17px;
        height: 17px;
        position: relative;
        cursor: pointer;
        &:before,
        &:after,
        span {
          display: block;
          width: 100%;
          height: 2px;
          border-radius: 1px;
          background: #1d1e1f;
          position: absolute;
          left: 0;
        }
        &:before,
        &:after {
          content: "";
        }
        &:before {
          top: 1px;
        }
        &:after {
          bottom: 1px;
        }
        span {
          top: 50%;
          transform: translateY(-50%);
        }
        &.close {
          &:before {
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
          }
          &:after {
            top: 50%;
            transform: translateY(-50%) rotate(-45deg);
          }
          span {
            opacity: 0;
          }
        }
      }
      .logo {
        display: blcok;
        width: 165px;
        height: 33px;
        @include pic_bg_cover;
        background-image: url(../assets/images/logo.png);
      }
      .search {
        width: 17px;
        height: 17px;
        cursor: pointer;
        @include pic_bg_cover;
        background-image: url(../assets/images/mob_search.png);
      }
      .empty {
        width: 17px;
        height: 17px;
      }
    }
    .menu {
      position: absolute;
      top: 75px;
      left: 0;
      right: 0;
      background: #fff;
      z-index: 100;
      padding-left: 32px;
      line-height: 24px;
      font-size: 14px;
      padding-bottom: 20px;
      .menu_item {
        margin-top: 12px;
        &:first-child {
          margin-top: 0;
        }
        .menu_link {
          cursor: pointer;
        }
        .menu_children {
          padding-left: 32px;
          .menu_children_link {
            cursor: pointer;
          }
          .menu_children_children {
            padding-left: 32px;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
@for $i from 2 to 6 {
  .page_theme_#{$i} {
    .Header {
      background: nth($bg_color, $i);
      .only_pc {
        border-bottom: 1px solid nth($text_color, $i);
        .top {
          .right {
            .search {
              border: 1px solid nth($text_color, $i);
              background-color: nth($bg_color, $i);
              input {
                color: nth($text_color, $i);
              }
            }
            .accessibility {
              color: nth($text_color, $i);
              background-color: nth($bg_color, $i);
            }
          }
        }
        .bottom {
          background: nth($bg_color, $i);
          .menu {
            .menu_item {
              .menu_link {
                color: nth($text_color, $i);
              }
              &:hover {
                .menu_link {
                  background: nth($bg_color, $i);
                }
                .menu_children {
                  background: nth($bg_color, $i);
                  .menu_children_item {
                    .menu_children_link {
                      color: nth($text_color, $i);
                      border-left: 2px solid nth($bg_color, $i);
                      &:hover {
                        background: nth($bg_color, $i);
                        border-left: 2px solid nth($text_color, $i);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .only_mob {
        border-bottom: 1px solid nth($text_color, $i);
        .top {
          .menu_icon {
            &:before,
            &:after,
            span {
              background: nth($text_color, $i);
            }
          }
        }
        .menu {
          background: nth($bg_color, $i);
        }
      }
    }
  }
}
</style>
