import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../pages/Home.vue'
import Column from '../pages/Column.vue'
import Topic from '../pages/Topic.vue'
import News from '../pages/News.vue'
import Search from '../pages/Search.vue'
import Petition from '../pages/Petition.vue'
import Page404 from '../pages/Page404.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/column/:id',
    name: 'Column',
    component: Column,
  },
  {
    path: '/topic/:id',
    name: 'Topic',
    component: Topic,
  },
  {
    path: '/news/:id',
    name: 'News',
    component: News,
  },
  {
    path: '/search/:keyword?',
    name: 'Search',
    component: Search,
  },
  {
    path: '/petition/:type?',
    name: 'Petition',
    component: Petition,
  },
  {
    path: '*',
    name: 'Page404',
    component: Page404,
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
}),
  originalPush = VueRouter.prototype.push,
  originalReplace = VueRouter.prototype.replace

router.beforeEach((to, from, next) => {
  document.documentElement.scrollTop = 0
  next()
})
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (location.path.search('://') > 0) {
    window.location.href = location.path
    return
  }
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
VueRouter.prototype.replace = function replace(location, onResolve, onReject) {
  if (location.path.search('://') > 0) {
    window.location.replace(location.path)
    return
  }
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

export default router
