import axios from 'axios'

axios.defaults.baseURL = ''
axios.defaults.timeout = 6000000
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded;charset=UTF-8'

axios.interceptors.request.use(
  config => {
    let token = localStorage.getItem('token')
    if (token != '') {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

axios.interceptors.response.use(
  res => {
    let code = res.status
    switch (code) {
      case 200:
        return Promise.resolve(res)
      default:
        return Promise.reject(res)
    }
  },
  err => {
    return Promise.reject(err)
  }
)

const handlePromise = (promise, resolve, reject) => {
  promise
    .then(res => {
      resolve(res.data)
    })
    .catch(err => {
      reject(err)
    })
}

export function get(url, params) {
  return new Promise((resolve, reject) => {
    handlePromise(axios.get(url, params), resolve, reject)
  })
}

export function post(url, params) {
  return new Promise((resolve, reject) => {
    handlePromise(axios.post(url, params), resolve, reject)
  })
}

export function put(url, params) {
  return new Promise((resolve, reject) => {
    handlePromise(axios.put(url, params), resolve, reject)
  })
}

export function del(url, params) {
  return new Promise((resolve, reject) => {
    handlePromise(axios.delete(url, { data: params }), resolve, reject)
  })
}

export function postFile(url, file, params) {
  return new Promise((resolve, reject) => {
    handlePromise(axios.post(url, file, params), resolve, reject)
  })
}
