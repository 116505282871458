<template>
  <div class="Footer">
    <div class="top">
      <div class="top_div">
        <div class="label">政府相关链接</div>
        <select @change="changeLink1">
          <option v-for="(item, index) in link1" :key="index" :value="item.id">{{item.name}}</option>
        </select>
        <select @change="changeLink2">
          <option v-for="(item, index) in link2" :key="index" :value="item.id">{{item.name}}</option>
        </select>
        <select @change="changeLink3">
          <option v-for="(item, index) in link3" :key="index" :value="item.id">{{item.name}}</option>
        </select>
      </div>
    </div>
    <div class="middle">
      <div class="sns">
        <a
          class="sns_item sns_item_douyin"
          href="https://www.douyin.com/user/MS4wLjABAAAAiMf8AZ6-aqfsDFJiKBhR8EYIiJt7waSbfQOdoC60PrI?extra_params=%7B%22search_id%22%3A%222021090214283901021117721323011357%22%2C%22search_result_id%22%3A%22102704223030%22%2C%22search_keyword%22%3A%22%E4%B8%8A%E6%B5%B7%E6%B6%88%E9%98%B2%22%2C%22search_type%22%3A%22user%22%7D&enter_method=search_result&enter_from=search_result"
          target="_blank"
        ></a>
        <a
          class="sns_item sns_item_toutiao"
          href="https://www.toutiao.com/c/user/token/MS4wLjABAAAAztOPB2cczx9cySf-PDH9NmQdCMx2PQHfk8A9GuiCvaE/"
          target="_blank"
        ></a>
        <a
          class="sns_item sns_item_weixin"
          href="https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MjM5MTA5NDg0NA==&scene=124#wechat_redirect"
          target="_blank"
        >
          <div class="qr">
            <span>扫码关注微信公众号</span>
          </div>
        </a>
        <a
          class="sns_item sns_item_kuaishou"
          href="https://www.kuaishou.com/profile/3xwvsm8xzuv6gqq"
          target="_blank"
        ></a>
        <a
          class="sns_item sns_item_weibo"
          href="https://weibo.com/shanghaixiaofang"
          target="_blank"
        ></a>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom_div">
        <div class="info">
          <div class="gov">
            <span id="_ideConac"></span>
          </div>
          <div class="jiucuo">
            <span id="_span_jiucuo"></span>
          </div>
          <div class="right">
            <div class="info_item">主办单位：上海市消防救援总队</div>
            <div class="info_item">联系方式：28955119</div>
          </div>
        </div>
        <div class="text">
          <div class="text_item gongan">沪公网安备 31010502006196号</div>
          <div class="text_item">沪ICP备2021016245号-2</div>
          <div class="text_item">网站标识码 bm88000008</div>
          <div class="text_item">上海消防网站 版权所有©严禁转载</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getFooterLink } from "@/axios/footer";

export default {
  name: "Footer",
  data() {
    return {
      link1: [
        {
          id: 0,
          name: "相关链接"
        }
      ],
      link2: [
        {
          id: 0,
          name: "总队导航"
        }
      ],
      link3: [
        {
          id: 0,
          name: "科研院所"
        }
      ]
    };
  },
  mounted() {
    this.loadLink();
    document.write(
      unescape(
        "%3Cscript src='https://dcs.conac.cn/js/02/000/0000/60999478/CA020000000609994780003.js' type='text/javascript'%3E%3C/script%3E"
      )
    );
    document.write(
      unescape(
        "%3Cscript id='_jiucuo_' sitecode='bm88000008' src='https://zfwzgl.www.gov.cn/exposure/jiucuo.js' type='text/javascript'%3E%3C/script%3E"
      )
    );
  },
  methods: {
    loadLink() {
      getFooterLink().then(res => {
        if (res.status == 1) {
          if (res.data[1]) {
            this.link1 = [...this.link1, ...res.data[1]];
          }
          if (res.data[2]) {
            this.link2 = [...this.link2, ...res.data[2]];
          }
          if (res.data[3]) {
            this.link3 = [...this.link3, ...res.data[3]];
          }
        }
      });
    },
    changeLink1(e) {
      let id = e.target.value;
      this.link1.forEach(item => {
        if (item.id == id) this.goLink(item);
      });
    },
    changeLink2(e) {
      let id = e.target.value;
      this.link2.forEach(item => {
        if (item.id == id) this.goLink(item);
      });
    },
    changeLink3(e) {
      let id = e.target.value;
      this.link3.forEach(item => {
        if (item.id == id) this.goLink(item);
      });
    },
    goLink(item) {
      if (item.link && item.link.length > 0) {
        let isMob = /Android|webOS|iPhone|iPod|BlackBerry/i.test(
          navigator.userAgent
        )
          ? true
          : false;
        if (isMob) {
          location.href = item.link;
        } else {
          window.open(item.link);
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.Footer {
  @media print {
    display: none;
  }
  .top {
    border-top: 1px solid #e5e5e5;
    background: #fff;
    display: none;
    .top_div {
      height: 83px;
      @include body_block;
      @include flex_between_center;
      @media (max-width: $mob-screen) {
        display: block;
        height: auto;
        padding-bottom: 8px;
      }
      .label {
        line-height: 22px;
        font-size: 16px;
        font-weight: 500;
        color: #000;
        @media (max-width: $mob-screen) {
          text-align: center;
          line-height: 20px;
          font-size: 12px;
          padding-top: 8px;
        }
      }
      select {
        display: block;
        width: 26.7%;
        height: 48px;
        border: 1px solid #e5e5e5;
        background: #fff;
        border-radius: 5px;
        padding-left: 21px;
        font-size: 16px;
        @media (max-width: $mob-screen) {
          margin: 0 auto;
          margin-top: 8px;
          width: 80%;
          height: 30px;
          font-size: 12px;
          padding-left: 16px;
        }
      }
    }
  }
  .middle {
    background: $primary_blue;
    padding-top: 39px;
    padding-bottom: 20px;
    @media (max-width: $mob-screen) {
      padding-top: 16px;
      padding-bottom: 16px;
    }
    .sns {
      width: 352px;
      margin: 0 auto;
      @include flex_between_center;
      @media (max-width: $mob-screen) {
        width: 260px;
      }
      .sns_item {
        width: 24px;
        height: 24px;
        cursor: pointer;
        @include pic_bg_cover;
        @media (max-width: $mob-screen) {
          width: 18px;
          height: 18px;
        }
        &.sns_item_douyin {
          background-image: url(../assets/images/footer_icon_douyin.png);
        }
        &.sns_item_toutiao {
          background-image: url(../assets/images/footer_icon_toutiao.png);
        }
        &.sns_item_weixin {
          background-image: url(../assets/images/footer_icon_weixin.png);
          .qr {
            &:before {
              background-image: url(../assets/images/footer_qr_weixin.png);
            }
          }
        }
        &.sns_item_kuaishou {
          background-image: url(../assets/images/footer_icon_kuaishou.png);
        }
        &.sns_item_weibo {
          background-image: url(../assets/images/footer_icon_weibo.png);
        }
        .qr {
          display: none;
        }
        &:hover {
          position: relative;
          .qr {
            display: block;
            position: absolute;
            top: -245px;
            left: 50%;
            transform: translateX(-50%);
            background: #fff;
            border-radius: 5px;
            box-shadow: 0 0 10px 0 rgba(60, 63, 82, 0.12);
            padding: 20px;
            &:before {
              display: block;
              content: "";
              width: 162px;
              height: 162px;
              background-repeat: no-repeat;
              background-position: center center;
              background-size: cover;
            }
            span {
              display: block;
              margin-top: 8px;
              text-align: center;
              line-height: 17px;
              font-size: 12px;
              color: #888;
            }
            &:after {
              display: block;
              content: "";
              width: 9px;
              height: 9px;
              background: #fff;
              position: absolute;
              left: 50%;
              bottom: -4px;
              transform: translateX(-50%) rotate(45deg);
            }
          }
        }
      }
    }
  }
  .bottom {
    background: $primary_blue;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    .bottom_div {
      padding-top: 14px;
      padding-bottom: 40px;
      @include body_block;
      @media (max-width: $mob-screen) {
        padding-top: 16px;
        padding-bottom: 16px;
      }
      .info {
        @include flex_center_center;
        flex-wrap: wrap;
        .gov {
          margin-right: 16px;
          /deep/ img {
            display: block;
            width: 80px;
          }
        }
        .jiucuo {
          margin-right: 20px;
          /deep/ img {
            display: block;
            width: 110px;
          }
        }
        .right {
          line-height: 17px;
          font-size: 12px;
          color: rgba(255, 255, 255, 0.6);
          @media (max-width: $mob-screen) {
            line-height: 16px;
            font-size: 11px;
          }
          .info_item {
            margin: 12px 0;
            @media (max-width: $mob-screen) {
              margin: 6px 0;
            }
          }
        }
      }
      .text {
        margin-top: 14px;
        line-height: 17px;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.6);
        @include flex_center_center;
        flex-wrap: wrap;
        @media (max-width: $mob-screen) {
          margin-top: 4px;
          line-height: 16px;
          font-size: 11px;
          color: #fff;
          text-align: center;
        }
        .text_item {
          padding: 0 5px;
          &.gongan {
            @include flex_center_center;
            &:before {
              display: block;
              content: "";
              width: 16px;
              height: 16px;
              @include pic_bg_cover;
              background-image: url(../assets/images/gongan.png);
            }
          }
          @media (max-width: $mob-screen) {
            margin-top: 9px;
          }
        }
      }
    }
  }
}
#_ideConac {
  display: block;
  text-align: center;
}
</style>
<style lang="scss">
@for $i from 2 to 6 {
  .page_theme_#{$i} {
    .Footer {
      .top {
        border-top: 1px solid nth($text_color, $i);
        background: nth($bg_color, $i);
        .top_div {
          .label {
            color: nth($text_color, $i);
          }
          select {
            border: 1px solid nth($text_color, $i);
            background: nth($bg_color, $i);
            color: nth($text_color, $i);
          }
        }
      }
      .middle {
        background: nth($bg_color, $i);
        .sns {
          .sns_item {
            &:hover {
              .qr {
                background: nth($bg_color, $i);
                span {
                  color: nth($text_color, $i);
                }
                &:after {
                  background: nth($bg_color, $i);
                }
              }
            }
          }
        }
      }
      .bottom {
        background: nth($bg_color, $i);
        border-top: 1px solid nth($text_color, $i);
        .bottom_div {
          .info {
            color: nth($text_color, $i);
          }
          .text {
            color: nth($text_color, $i);
            @media (max-width: $mob-screen) {
              color: nth($text_color, $i);
            }
          }
        }
      }
    }
  }
}
</style>
