<template>
  <div class="News">
    <Block404 v-if="show404" />
    <template v-else>
      <div v-if="data.breadcrumb && data.breadcrumb.length>0" class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{path: '/'}">首页</el-breadcrumb-item>
          <el-breadcrumb-item
            v-for="(item, index) in data.breadcrumb"
            :key="index"
            :to="item.path?{path: item.path}:null"
          >{{item.name}}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="news">
        <div class="title">{{data.title}}</div>
        <div v-if="data.textNumber && data.textNumber.length>0" class="code">{{data.textNumber}}</div>
        <div class="info">
          发布时间：{{data.releaseTime}}
          <span>阅读量：{{data.read}}</span>
        </div>
        <div class="desc" v-html="data.content"></div>
        <div v-if="data.files" class="files">
          <a
            v-for="(item, index) in data.files"
            :key="index"
            class="item"
            :href="item.url"
            :download="item.name"
          >{{item.name}}</a>
        </div>
        <div class="source">来源：{{data.contentSource}}</div>
        <div class="only_pc">
          <div class="tools">
            <div class="tools_item" @click="savePage">保存本页</div>
            <div class="tools_item" @click="printPage">打印本页</div>
            <div class="tools_item" @click="closePage">关闭窗口</div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { getNewsInfo } from "@/axios/news";
import Block404 from "@/components/Block404.vue";

export default {
  name: "News",
  components: {
    Block404
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      id: this.$route.params.id,
      show404: false,
      pageLoading: true,
      data: {},
      metaTitle: "",
      metaDate: "",
      metaSource: "",
      metaKeywords: "",
      metaAuthor: "",
      metaDesc: "",
      metaPic: ""
    };
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "ColumnName",
          content: this.metaTitle
        },
        {
          name: "ColumnDescription",
          content: this.metaDesc
        },
        {
          name: "ColumnKeywords",
          content: this.metaKeywords
        },
        {
          name: "ColumnType",
          content: ""
        },
        {
          name: "ArticleTitle",
          content: this.metaTitle
        },
        {
          name: "PubDate",
          content: this.metaDate
        },
        {
          name: "ContentSource",
          content: this.metaSource
        },
        {
          name: "Keywords",
          content: this.metaKeywords
        },
        {
          name: "Author",
          content: this.metaAuthor
        },
        {
          name: "Description",
          content: this.metaDesc
        },
        {
          name: "Image",
          content: this.metaPic
        },
        {
          name: "Url",
          content: location.href
        }
      ]
    };
  },
  watch: {
    $route(to) {
      if (to.params.id && to.params.id != this.id) {
        this.id = to.params.id;
        this.show404 = false;
        this.loadData();
      }
    }
  },
  methods: {
    loadData() {
      document.documentElement.scrollTop = 0;
      const loading = this.$loading({
        lock: true,
        background: "rgba(0, 0, 0, 0)"
      });
      this.pageLoading = true;
      this.data = {};
      let query = "id=" + this.id;
      getNewsInfo(query)
        .then(res => {
          if (res.status == 1) {
            let data = res.data;
            if (data.annex && data.annex.length > 0)
              data.files = JSON.parse(data.annex);
            this.data = data;
            this.metaTitle = res.data.title || "";
            this.metaDate = res.data.releaseTime || "";
            this.metaSource = res.data.contentSource || "";
            this.metaKeywords = res.data.keywords || "";
            this.metaAuthor = res.data.editor || "";
            this.metaDesc = res.data.description || "";
            this.metaPic = res.data.coverUrl || "";
          } else {
            this.show404 = true;
          }
        })
        .catch(err => {
          this.show404 = true;
        })
        .then(() => {
          loading.close();
          this.pageLoading = false;
        });
    },
    savePage() {
      let data = document.getElementsByTagName("html")[0].outerHTML,
        urlObject = window.URL || window.webkitURL || window,
        exportBlob = new Blob([data]),
        saveLink = document.createElementNS(
          "http://www.w3.org/1999/xhtml",
          "a"
        ),
        ev = document.createEvent("MouseEvents");
      saveLink.href = urlObject.createObjectURL(exportBlob);
      saveLink.download = "news" + this.id + ".html";
      ev.initMouseEvent(
        "click",
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
      saveLink.dispatchEvent(ev);
    },
    printPage() {
      window.print();
    },
    closePage() {
      window.close();
    }
  }
};
</script>
<style lang="scss" scoped>
.News {
  padding-top: 40px;
  padding-bottom: 60px;
  @include body_block;
  @media (max-width: $mob-screen) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .breadcrumb {
    margin-bottom: 20px;
    @media (max-width: $mob-screen) {
      margin-bottom: 8px;
    }
    @media print {
      display: none;
    }
    .el-breadcrumb {
      line-height: 28px;
      font-size: 20px;
      @media (max-width: $mob-screen) {
        line-height: 20px;
        font-size: 14px;
      }
    }
  }
  .news {
    background: #fff;
    padding: 48px 140px;
    @media (max-width: 900px) {
      padding: 48px 50px;
    }
    @media (max-width: $mob-screen) {
      padding: 16px;
    }
    .title {
      line-height: 50px;
      font-size: 36px;
      font-weight: 600;
      text-align: center;
      color: $primary_blue;
      @media (max-width: $mob-screen) {
        line-height: 30px;
        font-size: 18px;
      }
    }
    .code {
      margin-top: 20px;
      line-height: 28px;
      font-size: 20px;
      color: #888;
      text-align: center;
      @media (max-width: $mob-screen) {
        margin-top: 6px;
        line-height: 16px;
        font-size: 12px;
      }
    }
    .info {
      margin-top: 16px;
      line-height: 22px;
      font-size: 16px;
      color: #888;
      text-align: center;
      @media (max-width: $mob-screen) {
        margin-top: 6px;
        line-height: 16px;
        font-size: 12px;
      }
      span {
        padding-left: 20px;
        @media (max-width: $mob-screen) {
          display: block;
          padding-left: 0;
        }
      }
    }
    .desc {
      margin-top: 12px;
      line-height: 36px;
      font-size: 20px;
      color: #242424;
      @media (max-width: $mob-screen) {
        margin-top: 16px;
        line-height: 20px;
        font-size: 14px;
      }
      /deep/ img,
      /deep/ video {
        max-width: 100%;
      }
    }
    .files {
      margin-top: 20px;
      .item {
        display: block;
        line-height: 24px;
        font-size: 17px;
        color: #4d7cc7;
        padding-left: 30px;
        background: url(../assets/images/news_file_icon.png) no-repeat left 1px;
        background-size: 22px auto;
        margin-top: 12px;
        @media (max-width: $mob-screen) {
          line-height: 20px;
          font-size: 14px;
          background-size: 16px auto;
          padding-left: 20px;
          margin-top: 6px;
        }
        &:first-child {
          margin-top: 0;
        }
      }
    }
    .source {
      margin-top: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 40px;
      text-align: center;
      line-height: 22px;
      font-size: 16px;
      color: #888;
      @media (max-width: $mob-screen) {
        padding-bottom: 16px;
        line-height: 16px;
        font-size: 12px;
      }
    }
    .tools {
      margin-top: 40px;
      @include flex_center_center;
      .tools_item {
        width: 155px;
        line-height: 49px;
        background: #f5f6f9;
        text-align: center;
        margin: 0 20px;
        font-size: 16px;
        color: #000;
        cursor: pointer;
      }
    }
  }
}
</style>
<style lang="scss">
@for $i from 2 to 6 {
  .page_theme_#{$i} {
    .News {
      .news {
        background: nth($bg_color, $i);
        .title {
          color: nth($text_color, $i);
        }
        .code {
          color: nth($text_color, $i);
        }
        .info {
          color: nth($text_color, $i);
        }
        .desc {
          color: nth($text_color, $i);
        }
        .source {
          border-top: 1px solid nth($text_color, $i);
          color: nth($text_color, $i);
        }
        .tools {
          .tools_item {
            background: nth($bg_color, $i);
            color: nth($text_color, $i);
            border: 1px solid nth($text_color, $i);
          }
        }
      }
    }
  }
}
</style>